<div
	[class]="classes"
	class="block blue"
>
	<div
		class="block_header g-unselectable"
		appExpandBlockControl
		[class]="classOnBlockHeader"
	>
		<div
			*ngIf="typeColumn==='params'"
			class="toggle-button"
		>
			<span class="arrow"></span>
		</div>

		<ng-content select="[header]"></ng-content>
	</div>

	<div
		class="block_content"
		[class]="classOnBlockContent"
		[class.scroll-0px]="typeColumn === 'params'"
	>
		<ng-content select="[content]"></ng-content>
	</div>

	<ng-content></ng-content>
</div>
