import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExpandBlockControlModule } from '@shared/directives/expand-block-control/expand-block-control.module';
import { ColumnComponent } from '@shared/ui-components/column/column.component';

@NgModule({
	declarations: [ColumnComponent],
	exports: [ColumnComponent],
	imports: [CommonModule, ExpandBlockControlModule],
	providers: [],
})
export class ColumnModule {}
