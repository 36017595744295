import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges, ViewChild
} from '@angular/core';
import {ExpandBlockControlDirective} from '@shared/directives/expand-block-control/expand-block-control.directive';

type TypeColumn = 'content' | 'params';

@Component({
	selector: 'app-column',
	templateUrl: './column.component.html',
	styleUrls: ['./column.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnComponent implements OnInit, OnChanges {
	@Input()
	typeColumn: TypeColumn = 'content';

	@Input()
	classOnBlockContent: string;

	@Input()
	classOnBlockHeader: string;

	@ViewChild(ExpandBlockControlDirective)
	blockExpandDirective: ExpandBlockControlDirective;

	public classes: string;

	constructor(
		private _el: ElementRef
	) {}

	public ngOnInit(): void {
		this.initValues();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.typeColumn?.currentValue === 'params') {
			this._el.nativeElement.classList.add('params');
		}
	}

	private initValues(): void {
		if (this.typeColumn === 'params') {
			this.classes = 'block_wrapper params';
		} else {
			this.classes = 'content-window';
		}
	}
}
