import {
	Directive,
	OnInit,
	ElementRef,
	Renderer2,
	HostListener,
	EventEmitter,
	Output
} from '@angular/core';

@Directive({selector: '[appExpandBlockControl]'})
export class ExpandBlockControlDirective implements OnInit {
	@Output() ExpandOrHideEmitter = new EventEmitter<boolean>();

	private _isOpen: boolean;

	public expand;

	constructor(
		private _el: ElementRef,
		private _renderer: Renderer2
	) {
		this._isOpen = false;
	}

	@HostListener('document:click', ['$event'])
	public handleClick(event): void {
		if (
			(event.target &&
				event.target.attributes &&
				event.target.getAttribute('bsdatepickerdaydecorator') === '') ||
			event?.target?.attributes[1]?.nodeName !== 'header'
		) {
			return;
		}

		if (
			!!this.expand && this.expand.contains(event.target) &&
			(event.target.matches('span') || event.target.matches('li'))
		) {
			this.closeExpand();
		}
	}

	public ngOnInit(): void {
		this.expand = this._el.nativeElement.parentNode.querySelector(
			'.block_content'
		);

		this._renderer.listen(this._el.nativeElement, 'click', event => {
			if (this._isOpen) {
				return this.closeExpand();
			}

			this.openExpand();
		});
	}

	public clickExpand(): void {
		if (this._isOpen) {
			return this.closeExpand();
		}

		this.openExpand();
	}

	public closeExpand(): void {
		this.expand.classList.remove('expand-column');
		this._el.nativeElement.classList.remove('expand-column');

		const elem: HTMLElement = this._el.nativeElement;

		elem.parentElement.parentElement.parentElement.classList.remove('expand-column');

		this._isOpen = false;

		this.emitEventOnChangeSize();
	}

	public openExpand(): void {
		const elem: HTMLElement = this._el.nativeElement;

		elem.parentElement.parentElement.parentElement.classList.add('expand-column');

		this.expand.classList.add('expand-column');
		this._el.nativeElement.classList.add('expand-column');

		this._isOpen = true;

		this.emitEventOnChangeSize();
	}

	private emitEventOnChangeSize(): void {
		this.ExpandOrHideEmitter.emit(this._isOpen);
	}
}
